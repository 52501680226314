import React from 'react';
import Helmet from 'react-helmet';
import { Link as GatsbyLink } from 'gatsby';
import styled from 'styled-components';
import { pt, px, py, Margin } from 'styled-components-spacing';
import Grid from 'styled-components-grid';

import Section from '@nib-components/section';
import Heading from '@nib-components/heading';
import { Inline, Stack, Box } from '@nib/layout';
import FeaturePanel from '@nib-components/feature-panel';
import colors from '@nib-components/colors';
import Link from '@nib-components/link';
import Copy, { Bold } from '@nib-components/copy';
import {
  MessagingGraphicIcon,
  SupportGraphicIcon,
  PhoneSystemIcon,
  EmailSentSystemIcon,
  EmailSystemIcon,
} from '@nib/icons';
import {
  TableWrapper,
  Table,
  TableHead,
  TableHeading,
  TableHeadRow,
  TableBody,
  TableRow,
  TableData,
} from '@nib/table';

import Layout from '../../components/Layout';
import metrics from '../../metrics';

const SubSectionHeading = styled(Heading)`
  ${pt(1)}
`;

const title = 'Financial Advice And Strength';
const metaDescription =
  'Read our financial information and the rules around nib being a financial advisor';

const BoxedTableWrapper = ({ children }) => (
  <Box maxWidth={{ lg: '30%', md: '40%' }}>
    <TableWrapper>{children}</TableWrapper>
  </Box>
);

const RatingScaleTable = () => (
  <BoxedTableWrapper>
    <Table>
      <TableHead>
        <TableHeadRow>
          <TableHeading>Rating</TableHeading>
          <TableHeading>Description</TableHeading>
        </TableHeadRow>
      </TableHead>
      <TableBody>
        <TableRow>
          <TableData>AAA</TableData>
          <TableData>Extremely Strong</TableData>
        </TableRow>
        <TableRow>
          <TableData>AA</TableData>
          <TableData>Very Strong</TableData>
        </TableRow>
        <TableRow>
          <TableData>A</TableData>
          <TableData>Strong</TableData>
        </TableRow>
        <TableRow>
          <TableData>BBB</TableData>
          <TableData>Good</TableData>
        </TableRow>
        <TableRow>
          <TableData>BB</TableData>
          <TableData>Marginal</TableData>
        </TableRow>
        <TableRow>
          <TableData>B</TableData>
          <TableData>Weak</TableData>
        </TableRow>
        <TableRow>
          <TableData>CCC</TableData>
          <TableData>Very Weak</TableData>
        </TableRow>
        <TableRow>
          <TableData>CC</TableData>
          <TableData>Extremely Weak</TableData>
        </TableRow>
        <TableRow>
          <TableData>SD or D</TableData>
          <TableData>Selective Default or Default</TableData>
        </TableRow>
        <TableRow>
          <TableData>R</TableData>
          <TableData>Regulatory Action</TableData>
        </TableRow>
        <TableRow>
          <TableData>NR</TableData>
          <TableData>Not Rated</TableData>
        </TableRow>
      </TableBody>
    </Table>
  </BoxedTableWrapper>
);

const GridUnderline = styled(Grid.Unit)`
  border-bottom: 1px solid ${colors.sneezy};
`;

const FinanicalAdvice = () => (
  <Stack space={4}>
    <Copy measure={false}>
      nib nz limited (“nib”, “we” or “our”) holds a licence issued by the Financial Markets
      Authority to provide financial advice services in respect of certain insurance products.
    </Copy>
    <Grid>
      <GridUnderline size={1 / 2}>
        <Box padding={{ xs: 2, md: 3, lg: 4 }}>
          <Copy>
            <Bold>nib can provide financial advice on the following:</Bold>
          </Copy>
        </Box>
      </GridUnderline>
      <GridUnderline size={1 / 2}>
        <Box padding={{ xs: 2, md: 3, lg: 4 }}>
          <Copy>
            <Bold>Who issues & underwrites these products?</Bold>
          </Copy>
        </Box>
      </GridUnderline>
      <GridUnderline size={1 / 2}>
        <Box padding={{ xs: 2, md: 3, lg: 4 }}>
          <Copy>nib health insurance products</Copy>
        </Box>
      </GridUnderline>
      <GridUnderline size={1 / 2}>
        <Box padding={{ xs: 2, md: 3, lg: 4 }}>
          <Copy>nib</Copy>
        </Box>
      </GridUnderline>
      <GridUnderline size={1 / 2}>
        <Box padding={{ xs: 2, md: 3, lg: 4 }}>
          <Copy>AA Health Insurance products</Copy>
        </Box>
      </GridUnderline>
      <GridUnderline size={1 / 2}>
        <Box padding={{ xs: 2, md: 3, lg: 4 }}>
          <Copy>
            nib. This is an arrangement between The New Zealand Automobile Association Incorporated
            (AA) and nib.
          </Copy>
        </Box>
      </GridUnderline>
      <GridUnderline size={1 / 2}>
        <Box padding={{ xs: 2, md: 3, lg: 4 }}>
          <Stack space={2}>
            <Copy>
              Life & Living insurance products and the following nib nz insurance products that are
              no longer on sale:
            </Copy>
            <ul>
              <li>Home Loan Insurance</li>
              <li>Credit Card Repayment Insurance issued after 10 April 2011</li>
              <li>Life & Living Insurance issued before 16 August 2016</li>
              <li>Life Insurance issued before 14 November 2012</li>
            </ul>
          </Stack>
        </Box>
      </GridUnderline>
      <GridUnderline size={1 / 2}>
        <Box padding={{ xs: 2, md: 3, lg: 4 }}>
          <Stack space={2}>
            <Copy>nib nz insurance limited became part of the nib Group in 2022.</Copy>
            <Copy>
              nib nz limited provides financial advice on its own behalf and not on behalf of nib nz
              insurance limited.
            </Copy>
          </Stack>
        </Box>
      </GridUnderline>
    </Grid>

    <Heading size={{ xs: 4, md: 3 }} color="trueGreen" component="h2">
      Giving you financial advice
    </Heading>
    <Copy measure={false}>
      nib employs nominated representatives to provide financial advice to its members. The specific
      products that individual nominated representatives can advise members on may vary.
    </Copy>
    <Copy measure={false}>
      If you need advice on how nib, AA Health Insurance or nib nz insurance limited products
      compare to the products of other insurance providers, we recommend that you consult an
      independent financial advice provider.
    </Copy>

    <Heading size={{ xs: 4, md: 3 }} color="trueGreen" component="h3">
      We're committed to delivering good member outcomes
    </Heading>
    <Copy measure={false}>
      When you're dealing with nib or any nominated representatives giving advice on our behalf,
      know that we are bound by legal duties to:
    </Copy>
    <ul>
      <li>
        meet the standards of competence, knowledge, and skill set out in the code of conduct;
      </li>
      <li>give priority to your interests;</li>
      <li>exercise care, diligence, and skill; and</li>
      <li>
        meet the standards of ethical behaviour, conduct, and client care set out in the code of
        conduct.
      </li>
    </ul>
    <Copy measure={false}>
      We take all reasonable steps to ensure our advice is not materially influenced by nib’s own
      interests, or the interests of our associates.
    </Copy>

    <Heading size={{ xs: 4, md: 3 }} color="trueGreen" component="h3">
      You don’t pay for advice
    </Heading>
    <Copy measure={false}>
      nib does not charge fees for any financial advice given to you by nib or any nominated
      representatives giving advice on its behalf.
    </Copy>
    <Copy measure={false}>
      If you choose to buy one of our products, then premiums will need to be paid. You may also
      need to pay other amounts when you make a claim like an excess. Your premiums and any excesses
      are detailed in any quote provided to you.
    </Copy>
    <Heading size={{ xs: 4, md: 3 }} color="trueGreen" component="h3">
      Sales incentives and conflicts of interest
    </Heading>
    <Copy measure={false}>
      nib pays their nominated representatives a salary. They may also receive bonuses based on the
      insurance policies that they sell. Our sales governance framework requires certain conduct and
      other criteria be met before a bonus can be paid.
    </Copy>
    <ul>
      <li>
        nib pays fees to AA for referring customers to us. These fees are not linked to how our
        nominated representatives are paid or rewarded.
      </li>
      <li>
        nib nz insurance pays nib for services provided, including arranging Life & Living insurance
        and making financial advice available. These payments are not linked to sales outcomes or
        nominated representative remuneration.
      </li>
      <li>
        nib nz insurance pays fees to Kiwibank Limited for referring customers to it. These fees are
        not linked to how our nominated representatives are paid or rewarded.
      </li>
      <li>
        nib pays financial advisers for providing financial advice and arranging insurance. Your
        financial adviser can give you specific disclosure on the commission they are eligible to
        receive from nib.
      </li>
    </ul>
  </Stack>
);

const FeaturePanelStyled = styled(FeaturePanel)`
  ${py(2)};
`;

const FeatureStyled = styled(FeaturePanel.Feature)`
  ${px(2)};
  ${py(2)};
  box-shadow: ${colors.sneezy} 0px 4px 8px 0px;
`;

const ContactGrid = styled.div`
  display: grid;
  grid-template-columns: 105px 1fr;
  grid-row-gap: 0.5rem;
`;

const ComplaintsAndDisputes = (): JSX.Element => (
  <Stack space={4}>
    <SubSectionHeading
      size={{ sm: 4, md: 3, lg: 2 }}
      color="trueGreen"
      component="h3"
      align="center"
    >
      Complaints & dispute resolution
    </SubSectionHeading>
    <Copy measure={false}>
      We value feedback and seek this regularly by sending out surveys. Sometimes, despite our best
      efforts, we may not get things right. In these cases, our members can use our complaints
      process to try and resolve issues to their satisfaction.
    </Copy>
    <FeaturePanelStyled iconFill="trueGreen" space={1}>
      <FeatureStyled icon={MessagingGraphicIcon} title="Step 1: Contact us">
        <Stack space={4}>
          <Copy>
            If you have a complaint about the service or advice you have received from nib,{' '}
            <Link href="/complaints">contact us or complete our online form</Link>.
          </Copy>
          <Copy>
            An nib staff member will try to resolve your complaint in the first instance. If we
            can’t do that, the complaint will be escalated to a team leader or manager who will
            contact you within two business days.
          </Copy>
          <Copy>
            Any complaints not resolved by the team leader or manager will be escalated to the nib
            complaints committee – a group of senior managers who meet to review and look to resolve
            escalated.
          </Copy>
          <Copy>
            We will try to resolve your complaint within 14 days. If that is not possible, we will
            provide regular updates on progress.
          </Copy>
        </Stack>
      </FeatureStyled>
      <FeatureStyled
        icon={SupportGraphicIcon}
        title="Step 2: Get an external review of the decision"
      >
        <Stack space={4}>
          <Copy>
            If you are not satisfied with the outcome of your complaint, you have access to the
            Insurance & Financial Services Ombudsman (IFSO), a free, independent dispute resolution
            service.
          </Copy>
          <Copy>
            The IFSO may help investigate or resolve a complaint if it is not resolved to the
            complainant’s satisfaction using nib’s internal complaints process.
          </Copy>
          <Copy>
            To contact the IFSO please use their online{' '}
            <Link href="https://www.ifso.nz/make-a-complaint">complaint form</Link> or contact them:
          </Copy>
          <ContactGrid>
            <Inline space={2}>
              <PhoneSystemIcon />
              <Bold>Phone</Bold>
            </Inline>
            <Copy>0800 888 202</Copy>
            <Inline space={2}>
              <EmailSentSystemIcon />
              <Bold>Email</Bold>
            </Inline>
            <Copy>
              <Link component="a" href="mailto:info@ifso.nz">
                info@ifso.nz
              </Link>
            </Copy>
            <Inline space={2}>
              <EmailSystemIcon />
              <Bold>Post</Bold>
            </Inline>
            <Copy>IFSO Scheme PO Box 10-845 Wellington 6143 New Zealand</Copy>
          </ContactGrid>
        </Stack>
      </FeatureStyled>
    </FeaturePanelStyled>
  </Stack>
);

const FinancialStrength = (): JSX.Element => (
  <Stack space={4}>
    <Heading
      id="financial-strength-nibnz"
      size={{ xs: 2, md: 3, lg: 2 }}
      color="trueGreen"
      component="h3"
      align="center"
    >
      Financial strength – nib nz limited
    </Heading>
    <Stack space={2}>
      <Copy measure={false}>nib nz limited underwrites nib and AA Health insurance products.</Copy>
      <Copy measure={false}>
        nib nz limited has an A (Strong) Financial Strength Rating from S&P Global Ratings Australia
        Pty Ltd.
      </Copy>
      <Bold>Standard & Poor's rating scale</Bold>
      <RatingScaleTable />
    </Stack>
    <Copy measure={false}>
      Ratings from 'AA' to 'CCC' may be modified by the addition of a plus (+) or minus (-) sign to
      show relative standing within the major rating categories.
    </Copy>
    <Copy measure={false}>
      For more information, visit{' '}
      <Link href="https://www.spglobal.com/ratings/en/about/understanding-credit-ratings">
        www.spglobal.com/ratings/en/about/understanding-credit-ratings
      </Link>
      .
    </Copy>
  </Stack>
);

const SolvencyMargin = (): JSX.Element => (
  <Margin vertical={{ xs: 2, md: 6 }}>
    <Stack space={4}>
      <Heading
        id="solvency-margin-nibnz"
        size={{ xs: 2, md: 3, lg: 2 }}
        color="trueGreen"
        component="h3"
        align="center"
      >
        Solvency margin – nib nz limited
      </Heading>
      <Stack space={2}>
        <Copy measure={false}>
          The most recent interim solvency return filed by nib nz limited with the Reserve Bank of
          New Zealand is for the three months ended 30 September 2024. The solvency details at 30
          September 2024 were:
        </Copy>
        <BoxedTableWrapper>
          <Table>
            <TableBody>
              <TableRow>
                <TableData>Solvency Capital</TableData>
                <TableData>$577.2m</TableData>
              </TableRow>
              <TableRow>
                <TableData>Adjusted Prescribed Solvency Capital</TableData>
                <TableData>$513.7m</TableData>
              </TableRow>
              <TableRow>
                <TableData>Adjusted Solvency Margin</TableData>
                <TableData>$63.4m</TableData>
              </TableRow>
              <TableRow>
                <TableData>Adjusted Solvency Ratio</TableData>
                <TableData>112%</TableData>
              </TableRow>
            </TableBody>
          </Table>
        </BoxedTableWrapper>
        <Copy measure={false}>
          Note: The RBNZ introduced the Interim Solvency Standard 2023 in October 2022 and this was
          effective from 1 January 2023. The new standard has resulted in a change to the way
          solvency information is calculated. The 'Adjusted prescribed capital requirement' is the
          minimum amount of capital required to be held for solvency purposes. 'Solvency Capital' is
          the amount of capital actually held for those purposes. The difference between the two is
          the 'Adjusted solvency margin'.
        </Copy>
      </Stack>
    </Stack>
  </Margin>
);

const FinancialStrengthInsurance = (): JSX.Element => (
  <Margin vertical={{ xs: 2, md: 6 }}>
    <Stack space={4}>
      <Heading
        id="financial-strength-nibnz-insurance"
        size={{ xs: 2, md: 3, lg: 2 }}
        color="trueGreen"
        component="h3"
        align="center"
      >
        Financial strength – nib nz insurance limited
      </Heading>
      <Stack space={2}>
        <Copy measure={false}>
          nib nz insurance limited issues and underwrites Life & Living insurance products, and the
          following insurance products that are no longer on sale:
        </Copy>
        <ul>
          <li>Home Loan Insurance,</li>
          <li>Credit Card Repayment Insurance issued after 10 April 2011,</li>
          <li>Life & Living Insurance issued before 16 August 2016, and</li>
          <li>Life Insurance arranged before 14 November 2012.</li>
        </ul>
        <Copy measure={false}>
          nib nz insurance limited has an A (Strong) Financial Strength Rating from S&amp;P Global
          Ratings Australia Pty Ltd.
        </Copy>
        <Bold>Standard & Poor’s rating scale</Bold>
        <RatingScaleTable />
      </Stack>
      <Copy measure={false}>
        Ratings from 'AA' to 'CCC' may be modified by the addition of a plus (+) or minus (-) sign
        to show relative standing within the major rating categories.
      </Copy>
      <Copy measure={false}>
        The rating scale above is in summary form. A full description of the rating scale can be
        found at{' '}
        <Link href="https://www.spglobal.com/ratings/en/about/understanding-credit-ratings">
          www.spglobal.com/ratings/en/about/understanding-credit-ratings
        </Link>
        .
      </Copy>
    </Stack>
  </Margin>
);

const SolvencyMarginInsurance = (): JSX.Element => (
  <Margin vertical={{ xs: 2, md: 6 }}>
    <Stack space={4}>
      <Heading
        id="solvency-margin-nibnz-insurance"
        size={{ xs: 2, md: 3, lg: 2 }}
        color="trueGreen"
        component="h3"
        align="center"
      >
        Solvency margin and statutory fund - nib nz insurance limited
      </Heading>
      <Copy measure={false}>
        The most recent interim solvency return filed by nib nz insurance limited with the Reserve
        Bank of New Zealand is for the three months ended 30 September 2024. The solvency details at
        30 September 2024 were:
      </Copy>
      <TableWrapper>
        <Table>
          <TableHead>
            <TableHeading></TableHeading>
            <TableHeading>Statutory fund</TableHeading>
            <TableHeading>All of nib nz insurance limited</TableHeading>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableData>Solvency Capital</TableData>
              <TableData>$34.6m</TableData>
              <TableData>$34.6m</TableData>
            </TableRow>
            <TableRow>
              <TableData>Adjusted prescribed capital requirement</TableData>
              <TableData>$22.8m</TableData>
              <TableData>$22.8m</TableData>
            </TableRow>
            <TableRow>
              <TableData>Adjusted solvency margin</TableData>
              <TableData>$11.8m</TableData>
              <TableData>$11.8m</TableData>
            </TableRow>
            <TableRow>
              <TableData>Adjusted solvency ratio</TableData>
              <TableData>152%</TableData>
              <TableData>152%</TableData>
            </TableRow>
          </TableBody>
        </Table>
      </TableWrapper>
      <Copy measure={false}>
        nib nz insurance limited has one statutory fund, which constitutes the whole of the company.
      </Copy>
      <Copy measure={false}>
        Note: The RBNZ introduced the Interim Solvency Standard 2023 in October 2022 and this was
        effective from 1 January 2023. The new standard has resulted in a change to the way solvency
        information is calculated. The 'Adjusted prescribed capital requirement' is the minimum
        amount of capital required to be held for solvency purposes. 'Solvency Capital' is the
        amount of capital actually held for those purposes. The difference between the two is the
        'Adjusted solvency margin'.
      </Copy>
    </Stack>
  </Margin>
);

const FinanicalAdvicePage = (): JSX.Element => (
  <Layout>
    <Helmet>
      <title>{title} | nib</title>
      <meta name="description" content={metaDescription} />
    </Helmet>
    <Section role="section">
      <Stack space={2}>
        <Link id="ga-inlink-static-site-page-9" component={GatsbyLink} to="/about-nib">
          &lt; Back
        </Link>
        <Heading size={1} color="trueGreen" align="center">
          Financial advice
        </Heading>
        <FinanicalAdvice />
        <ComplaintsAndDisputes />
        <FinancialStrength />
        <SolvencyMargin />
        <FinancialStrengthInsurance />
        <SolvencyMarginInsurance />
      </Stack>
    </Section>
  </Layout>
);

export default metrics({ pageName: 'finanical-advice-strength' })(FinanicalAdvicePage);
